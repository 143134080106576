.DigitalNomad {
  h4 {
    margin: 0;
    padding: 1.2rem;
    border-bottom: 1px solid grey;

    span {
      float: left;
    }
  }

  div {
    padding: 0.5rem;
  }
}
