.Country {
  h4 {
    margin: 0;
    padding: 1.2rem;
    border-bottom: 1px solid grey;

    span {
      float: left;
    }

    img {
      width: 1.8rem;
      height: 1.2rem;
      border: 1px solid whitesmoke;
      float: right;
    }
  }

  div {
    padding: 0.5rem;
  }

  section {
    position: relative;
    bottom: 1.2rem;
    right: 1.2rem;
    text-align: right;

    a {
      font-family: "Times New Roman", Times, serif;
      text-decoration: none;
      text-align: center;
      color: gray;
      background-color: whitesmoke;
      border-radius: 25%;
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      padding: 0.3rem;
    }

    a:hover {
      color: black;
    }
  }
}
