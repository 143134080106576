.Menu {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;

  ul {
    margin: 0;
    padding: 0;
    padding-left: 3rem;
    border: 0;
    list-style-type: none;
    background-image: linear-gradient(180deg, orangered, red);

    text-align: left;

    li {
      margin: 0;
      display: inline-block;
      text-transform: uppercase;
      text-align: center;

      &.active {
        color: white;
        background-color: orangered;
      }

      &:last-child {
        position: absolute;
        right: 3rem;
      }

      a {
        display: block;
        padding: 0.5rem 1rem;
        color: whitesmoke;
        text-decoration: none;

        &:hover {
          color: white;
        }

        span {
          font-size: 1.2rem;
          line-height: 1.2rem;
        }
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .Menu {
    ul {
      padding-left: 2rem;
      li {
        &:last-child {
          right: 2rem;
        }

        a {
          div {
            display: none;
          }
        }
      }
    }
  }
}
