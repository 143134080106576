.Location {
  position: relative;

  div.cover {
    position: relative;
    margin: auto;
    background-color: darkgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    border-radius: 0.5rem;
    padding-bottom: 100%;
    opacity: 0.8;
  }

  h4 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 1.2rem;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;

    span {
      float: left;
    }
  }

  div {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    background-color: rgba($color: #000000, $alpha: 0.5);
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }

  section {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    text-align: right;

    a {
      font-family: "Times New Roman", Times, serif;
      text-decoration: none;
      text-align: center;
      color: gray;
      background-color: whitesmoke;
      border-radius: 25%;
      display: inline-block;
      width: 1.6rem;
      height: 1.6rem;
      padding: 0.3rem;
    }

    a:hover {
      color: black;
    }
  }
}
