* {
  box-sizing: border-box;
}

html,
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: whitesmoke;
  background-color: #181c24;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    text-align: center;
  }

  select {
    padding: 0.5rem;
    border-color: #666;
    border-radius: 0.5rem;
    background-color: #181c24;
    color: whitesmoke;
  }

  input {
    width: 100%;
    padding: 0.5rem;
    border-radius: 0.5rem;
    border: 1px solid #666;
    outline: none;
    background-color: #181c24;
    color: whitesmoke;
  }
}

.flex-container {
  display: flex;
  justify-content: space-between;
}
